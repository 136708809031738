import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { from, Observable, of } from 'rxjs';
import {
  Auth,
  authState,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  updateProfile,
  UserInfo,
} from '@angular/fire/auth';
import { Router } from '@angular/router';
@Injectable()
export class AuthService {

  baseUrl: any = '';
  mediaURL: any = '';

  constructor(
    private http: HttpClient,
    private auth: Auth,
    private router: Router,
  ) {
    this.baseUrl = environment.baseUrl;
    this.mediaURL = environment.mediaURL;
  }


  Login(payload: any) {
    return this.http.post((this.baseUrl + environment.apiUrls.apis.Login), payload, {
      headers: new HttpHeaders().set('Basic', `${environment.authToken}`)
    });
  }

  getParameterList() {
    return this.http.get((this.baseUrl + environment.apiUrls.apis.getParameterList), {
      headers: new HttpHeaders().set('Basic', `${environment.authToken}`)
    }).toPromise();
  }


  logout() {
    localStorage.clear();
    const key = btoa("isLoggedIn")
    localStorage.removeItem(key);
    localStorage.removeItem('role');
    localStorage.removeItem('userid');
    localStorage.removeItem('firstname');
    localStorage.removeItem('lastname');
    localStorage.removeItem('password');
    localStorage.removeItem('id');
    localStorage.removeItem('token');
    localStorage.removeItem('backdaysentrylimit');
    this.router.navigate(['login']);
  }


  // logout() {
  //   return from(this.auth.signOut());
  // }
}
