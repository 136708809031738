import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  cover: any="";
  name: any="";

  constructor(
    private router: Router   
    ) { 
      this.cover=localStorage.getItem('cover');
      this.name=localStorage.getItem('name')
    }

  ngOnInit() { }
  redirect(path) {
    this.router.navigate([path]);
  }

  openprofile()
  {
    this.router.navigate(['profile'] );
  }

}
