// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: "https://apiicaa.apiitech.co.in/index.php/",
  mediaURL: 'https://apiicaa.apiitech.co.in/uploads/',
  authToken: 'icaa',
  version:5,
  playstoreurl:'https://play.google.com/store/apps/details?id=com.app.consignment&hl=en-IN',
  firebase: {
    apiKey: "AIzaSyDxLYCop7kgMKrMCjlnrlJBJWcXB1lByTU",
    authDomain: "chatapp-5e797.firebaseapp.com",
    projectId: "chatapp-5e797",
    storageBucket: "chatapp-5e797.appspot.com",
    messagingSenderId: "409361595207",
    appId: "1:409361595207:web:48ef145edd4291b956ea62",

  },
  apiUrls: {

    apis: {
      Login: 'users/Login',
      getParameterList: 'GlobalVariable/getAll',



      eventsave: 'events/save',
      editevent: 'events/editlist',
      eventall: 'events/getAll',
      deleteevent: 'events/deleteList',
      geteventid: 'events/getById',
      eventregistrationsave: 'eventregistration/save',
      editeventregistration: 'eventregistration/editlist',
      eventregistrationall: 'eventregistration/getAll',
      deleteeventregistration: 'eventregistration/deleteList',
      geteventregistrationid: 'eventregistration/getById',
      getregistrationall:'eventregistration/getregistrationall',
      getmobile:'eventregistration/getmobile',
      getotpdetails:'eventregistration/getotpdetails',
      getregistrationdetails:'eventregistration/getregistrationdetails',
      savelog:'logs/save',
      getlogreport:'logs/getlogreport',
      savelogbulk:'logs/savebulk',
      usersList:'users/getAll',
      saveusers:'users/save',
      updateusers:'users/editList',
      deleteusers:'users/deleteList',
      getusersbycode:'users/getByItemId',
      getusers:'users/getById',
      send_usercreationmail:'users/send_usercreationmail',
      saveSettings:'GlobalVariable/save',
      deleteSettings:'GlobalVariable/deleteList',
      updateSettings:'GlobalVariable/editList',
      getType:'GlobalVariable/getByType',
      
      subscriptionplansave: 'subscriptionplans/save',
      editsubscriptionplan: 'subscriptionplans/editlist',
      subscriptionplanall: 'subscriptionplans/getAll',
      deletesubscriptionplan: 'subscriptionplans/deleteList',
      getsubscriptionplanid: 'subscriptionplans/getById',
      subscriptionplans:'subscriptionplans/getsubscriptionplans',
	  
    }

  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
