import { Component, HostListener, OnInit } from '@angular/core';
import { Router,NavigationExtras } from '@angular/router';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../services/authentication.service';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  name: any = '';
  mediaURL: any = '';

 


  role:any='';
  image:any='';
  email:any='';
  mobile:any='';

  constructor(
    private router: Router,
    private _auth:AuthService,
    private location: Location
    ) { 
      this.mediaURL = environment.mediaURL
      this.name = localStorage.getItem('name');
      this.role= localStorage.getItem('role');
      this.image=localStorage.getItem('cover')
      this.email= localStorage.getItem('userid');
      this.mobile=localStorage.getItem('mobile')
    }

  ngOnInit() {
    
    this.mediaURL = environment.mediaURL;
    this.name = localStorage.getItem('name');
    this.role= localStorage.getItem('role');
  
  }

 
  ionViewDidEnter() {
    this.mediaURL = environment.mediaURL;
    this.name = localStorage.getItem('name');
    this.role= localStorage.getItem('role');
  }

  logout(){
    // localStorage.clear()
    // this._auth.logout();
    // this.router.navigate(['login']);
    this._auth.logout();

  }

  redirect(path) {
    this.router.navigate([path]);
  }
 

  @HostListener('swipe', ['$event'])
  onSwipe(event: any) {
    const x = Math.abs(event.deltaX) > 40 ? (event.deltaX > 0 ? 'right' : 'left') : '';
    if (x === 'right') {
      this.location.back();
    }
  }

  back()
  {
    this.location.back();
  }

}
